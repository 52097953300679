// extracted by mini-css-extract-plugin
export var accordionArea = "Accordion-module--accordionArea--20c2c";
export var accordionSection = "Accordion-module--accordionSection--00086";
export var accordionSectionInner = "Accordion-module--accordionSectionInner--12dfe";
export var accordionSectionPrimary = "Accordion-module--accordionSectionPrimary--8a861";
export var accordionSectionSecondary = "Accordion-module--accordionSectionSecondary--5c4ae";
export var box = "Accordion-module--box--5af70";
export var close = "Accordion-module--close--9d93c";
export var head = "Accordion-module--head--22c96";
export var headSubtext = "Accordion-module--headSubtext--26483";
export var headText = "Accordion-module--headText--4772d";
export var open = "Accordion-module--open--c399f";
export var slideDown = "Accordion-module--slide-down--ddc55";
export var title = "Accordion-module--title--393c1";
export var titleEn = "Accordion-module--titleEn--bc51b";
export var titleJp = "Accordion-module--titleJp--80ef4";