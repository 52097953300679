// src/pages/contact.js
import * as React from 'react';
import Accordion from '../components/Accordion/Accordion';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import CtrSection from '../components/CtrSection/CtrSection';
import FlowChart from '../components/FlowChart/flowChart';
import PriceingPlan from '../components/PricingPlan/PricingPlan';
import TextImageSection from '../components/TextImageSection/textImageSection';
import Layout from '../components/layout';
import Seo from '../components/seo';

const FlowPage = ({ data }) => {
  return (
    <Layout>
      <TextImageSection
        subtitle="HOMEPAGE｜ホームページ制作"
        title="受付からホームページ制作までの流れ"
        text="受付からホームページ制作までの流れをご紹介します。Q&Aも掲載しています。"
        imageUrl="/images/haikei3.jpg"
        imageAlt="ホームページ制作"
      />
      <Breadcrumb
        crumbs={[
          { pathname: '/', crumbLabel: 'TOP' },
          { pathname: '/about', crumbLabel: '企業概要' },
          { pathname: '/business', crumbLabel: '事業内容' },
          { pathname: '/flow', crumbLabel: '受付の流れ' },
        ]}
      />
      <FlowChart />
      <Accordion
        title="よくある質問"
        items={[
          {
            question:
              'ビデオミーティング申込をしたら、絶対に契約しなければならないのですか？',
            answer:
              'そんなことはありません。ビデオミーティングは、お客様のご要望をお伺いし、お打ち合わせを行うためのものです。お打ち合わせ後、ご検討いただいた上で、ご契約いただくかどうかを決定していただいても構いません。',
          },
          {
            question: 'ビデオミーティングでは、どのようなことを行いますか？',
            answer:
              'ビデオミーティングでは、プランの説明、お互いの自己紹介、お客様のご要望やご予算についてお伺いし、お打ち合わせを行います。お客様のご要望に合わせた最適なプランをご提案いたします。',
          },
          {
            question: 'サイトのセキュリティ対策はどのように行っていますか？',
            answer:
              '私たちは、セキュリティの確保を重視しています。SSL証明書の設定、定期的なセキュリティチェック、最新のセキュリティ対策を施すことで、お客様のウェブサイトを安全に保ちます。',
          },
          {
            question: '完成後、自分でコンテンツを更新することは可能ですか？',
            answer:
              'はい、CMS（コンテンツ管理システム）を設定することにより、お客様自身で簡単にコンテンツの更新を行うことができます。',
          },
          {
            question: '制作期間はどのくらいかかりますか？',
            answer:
              '制作期間は、ウェブサイトの規模や要件によって異なりますが、最短は2週間が目安となります。お客様都合で、制作期間が伸びる場合もございます。詳細はお見積もりの際にご説明いたします。',
          },
          {
            question: 'SEO対策やアクセス解析はできますか？',
            answer:
              'はい、SEO対策やアクセス解析も行っております。ウェブサイトの最適化や、アクセス数、滞在時間などの分析を行い、より集客力のあるサイトづくりをサポートいたします。',
          },
          {
            question: 'スマートフォンでの表示は最適化されていますか？',
            answer:
              'はい、レスポンシブWebデザインを採用しており、スマートフォンやタブレット端末でも最適化された表示が可能です。',
          },
          {
            question: '制作後のサポートはありますか？',
            answer:
              'はい、ウェブサイト公開後も定期的な保守・更新のサポートを行っております。アップデートや不具合対応など、サイトの安定運用をバックアップいたします。',
          },
        ]}
      />
      <PriceingPlan />
      <CtrSection />
    </Layout>
  );
};

export default FlowPage;

export const Head = () => (
  <Seo
    title="受付からホームページ制作までの流れ"
    description="デジタルクリエイティブ株式会社の受付からホームページ制作までの流れをご紹介します。Q&Aも掲載しています。"
  />
);
