// src/components/FlowChart/flowChart.js
import { Link } from 'gatsby';
import * as React from 'react';
import * as styles from './flowChart.module.css';

const FlowChart = () => {
  return (
    <>
      <section className={styles.flowChart}>
        <div className={styles.title}>
          <h1>
            <span className={styles.titleEn}>FLOW CHART</span>
            <span className={styles.titleJp}>
              受付からホームページ制作までの流れ
            </span>
          </h1>
        </div>
        <div className={styles.steps}>
          <div className={styles.step}>
            <h3>受付</h3>
            <ul>
              <li>ビデオミーティング申込</li>
              <li>日程調整</li>
              <li>ヒヤリングシートの記入</li>
            </ul>
          </div>
          <div className={styles.step}>
            <h3>ミーティング</h3>
            <ul>
              <li>目標設定</li>
              <li>プラン設定</li>
              <li>見積書、契約書、送付</li>
              <li>入金後すぐに着手</li>
              <li>取材</li>
            </ul>
          </div>
          <div className={styles.step}>
            <h3>制作</h3>
            <ul>
              <li>デザイン</li>
              <li>コーディング</li>
              <li>環境整備</li>
            </ul>
          </div>
          <div className={styles.step}>
            <h3>公開・運用</h3>
            <ul>
              <li>コンテンツ作成</li>
              <li>SEO対策</li>
              <li>デザイン変更</li>
              <li>プログラム変更</li>
              <li>広告運用</li>
              <li>SNS</li>
            </ul>
          </div>
        </div>
      </section>
      <section className={styles.fourStepsSection}>
        <div className={styles.step2}>
          <h3>1 受付</h3>
          <p>
            まずは、お客様に
            <Link to="/meeting">ビデオミーティングの予約</Link>
            をしていただきます。その際、ヒアリングシートを記入していただきます。その内容を元に、ミーティングを行います。
          </p>
        </div>
        <div className={styles.step2}>
          <h3>2 ミーティング</h3>
          <p>
            ミーティングではプランの説明、目標設定を行います。その内容をもとに、プランを設定し、見積書、契約書を送付します。入金後、すぐに制作に着手します。
          </p>
        </div>
        <div className={styles.step2}>
          <h3>3 制作</h3>
          <p>
            お客様の目的を達成するため、企画からデザイン、コーディングまで一貫して対応します。新規・リニューアル、どちらのホームページ制作も可能です。
          </p>
        </div>
        <div className={styles.step2}>
          <h3>4 公開・運用</h3>
          <p>
            ホームページは公開がゴールではなく、スタートです。当社ではアプリケーション管理・サーバー管理から、SEO対策・広告運用までトータルサポートが可能です。
          </p>
        </div>
      </section>
    </>
  );
};

export default FlowChart;
