// src/components/CtrSection/CtrSection.tsx
import React from 'react';
import Button from '../commons/Button/button';
import * as styles from './CtrSection.module.css';

interface CtrSectionProps {
  title: string;
  subTitle: string;
  description: string;
}

const CtrSection: React.FC<CtrSectionProps> = ({
  title,
  subTitle,
  description,
}) => {
  return (
    <section className={styles.topSection}>
    
      <div className={styles.title}>
      <h1>
        ホームページ制作・SEO対策・Web集客<br/>
        お任せください！
      </h1>
      </div>
      
      <div className={styles.buttonWrapper}>
        <Button to="/meeting" variant="gradient" size="large">
          ミーティングのご予約
        </Button>
        <Button to="/contact" variant="gradient" size="large">
          お問い合わせ
        </Button>
      </div>

      <div className={styles.explainWrapper}>
        <div className={styles.explain}>
          <p>
            八王子を中心に全国の飲食店のデジタル支援、具体的にはホームページ制作・SEO対策・Web集客を行うデジタルクリエイティブ株式会社です。弊社はホームページの制作をするだけではなく、ホームページ制作後のSEO対策・集客・運用に力を入れています。更には、表示速度やレスポンシブ対応・ユーザビリティの向上など常にユーザー目線のUI/UXを考え、Webを通して成果や効果を実感・体感できるホームページの制作を行います。
          </p>
        </div>
      </div>
    

      <div className={styles.image}>
        <img src="/images/haikei3.jpg" alt="haikei" />
      </div>
    </section>
  );
};

export default CtrSection;
