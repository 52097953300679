// extracted by mini-css-extract-plugin
export var desktopTable = "PricingPlan-module--desktopTable--73aa2";
export var mobileTable = "PricingPlan-module--mobileTable--61414";
export var ppp = "PricingPlan-module--ppp--e3251";
export var priceStrike = "PricingPlan-module--priceStrike--62217";
export var priceStrong = "PricingPlan-module--priceStrong--8bf96";
export var sectionInner = "PricingPlan-module--sectionInner--335d3";
export var sectionPrimary = "PricingPlan-module--sectionPrimary--ffd32";
export var sectionSecondary = "PricingPlan-module--sectionSecondary--6a42e";
export var sellZone = "PricingPlan-module--sellZone--18081";
export var tableScroll = "PricingPlan-module--tableScroll--fc3e4";
export var title = "PricingPlan-module--title--e88a8";
export var titleEn = "PricingPlan-module--titleEn--a85b7";
export var titleJp = "PricingPlan-module--titleJp--60364";