import React from 'react';
import * as styles from './PricingPlan.module.css';

interface PricingPlanProps {
  title: string;
  price: {
    yearly: number;
    monthly: number;
  };
  description: string;
  colorType: 'primary' | 'secondary';
}

const PricingPlan: React.FC<PricingPlanProps> = ({
  title,
  price,
  description,
  colorType,
}) => {
  let SectionClass = styles.sectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    SectionClass = styles.sectionPrimary;
  } else if (colorType === 'secondary') {
    SectionClass = styles.sectionSecondary;
  }
  return (
    <section className={SectionClass}>
      <div className={styles.sectionInner}>
        <div className={styles.title}>
          <h2>
            <span className={styles.titleEn}>PRICE </span>
            <span className={styles.titleJp}>料金表</span>
          </h2>
        </div>
        <div className={styles.tableScroll}>
          <div className={styles.desktopTable}>
            <table className={styles.ppp}>
              <thead>
                <tr>
                  <th></th>
                  <th>サブスクプラン</th>
                  <th>一括払いプラン</th>
                </tr>
              </thead>
              <tbody>
                <tr className={styles.sellZone}>
                  <td>入会金</td>
                  <td>
                    <text className={styles.priceStrike}>98000円</text> →{' '}
                    <strong className={styles.priceStrong}>0</strong>円
                  </td>
                  <td>
                    298000円<span>〜</span>
                  </td>
                </tr>
                <tr>
                  <td>料金(サーバー費用)</td>
                  <td>9800円/月</td>
                  <td>5000円/月</td>
                </tr>
                <tr>
                  <td>追加1ページにつき</td>
                  <td>+1000円/月</td>
                  <td>+20000円</td>
                </tr>
                <tr>
                  <td>無料のページ作成</td>
                  <td>1ページまで</td>
                  <td>6ページまで</td>
                </tr>
                <tr>
                  <td>ドメイン</td>
                  <td>標準対応</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td>SSL証明書</td>
                  <td>標準対応</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td>セキュリティ</td>
                  <td>標準対応</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td>CDN</td>
                  <td>標準対応</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td>標準的なSEO対策</td>
                  <td>標準対応</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td>記事投稿機能</td>
                  <td>+5000円/月</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td>メニューページ</td>
                  <td>+2000円/月</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td>お問い合わせページ</td>
                  <td>+2000円/月</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td>記事代行月1記事につき</td>
                  <td>+8000円/月</td>
                  <td>+8000円/月</td>
                </tr>
                <tr>
                  <td>Eコマースシステム(別途決済手数料)</td>
                  <td>+8000円/月</td>
                  <td>+160000円</td>
                </tr>
                <tr>
                  <td>スマート予約システム</td>
                  <td>+5000円/月</td>
                  <td>+100000円</td>
                </tr>
                <tr>
                  <td>多言語化対応(1言語ごと)</td>
                  <td>+5000円/月</td>
                  <td>+100000円</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.mobileTable}>
            <table className={styles.ppp}>
              <thead>
                <tr>
                  <th>サブスクプラン</th>
                  <th>一括払いプラン</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>入会金</td>
                </tr>
                <tr className={styles.sellZone}>
                  <td>
                    <text className={styles.priceStrike}>98000円</text> →{' '}
                    <strong className={styles.priceStrong}>0</strong>円
                  </td>
                  <td>298000円</td>
                </tr>
                <tr>
                  <td colSpan={2}>料金(サーバー費用)</td>
                </tr>
                <tr>
                  <td>9800円/月</td>
                  <td>5000円/月</td>
                </tr>
                <tr>
                  <td colSpan={2}>追加1ページにつき</td>
                </tr>
                <tr>
                  <td>+1000円/月</td>
                  <td>+20000円</td>
                </tr>
                <tr>
                  <td colSpan={2}>無料のページ作成</td>
                </tr>
                <tr>
                  <td>1ページまで</td>
                  <td>6ページまで</td>
                </tr>
                <tr>
                  <td colSpan={2}>ドメイン</td>
                </tr>
                <tr>
                  <td>標準対応</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td colSpan={2}>SSL証明書</td>
                </tr>
                <tr>
                  <td>標準対応</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td colSpan={2}>セキュリティ</td>
                </tr>
                <tr>
                  <td>標準対応</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td colSpan={2}>CDN</td>
                </tr>
                <tr>
                  <td>標準対応</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td colSpan={2}>標準的なSEO対策</td>
                </tr>
                <tr>
                  <td>標準対応</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td colSpan={2}>記事投稿機能</td>
                </tr>
                <tr>
                  <td>+5000円/月</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td colSpan={2}>メニューページ</td>
                </tr>
                <tr>
                  <td>+2000円/月</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td colSpan={2}>お問い合わせページ</td>
                </tr>
                <tr>
                  <td>+2000円/月</td>
                  <td>標準対応</td>
                </tr>
                <tr>
                  <td colSpan={2}>記事代行月1記事につき</td>
                </tr>
                <tr>
                  <td>+8000円/月</td>
                  <td>+8000円/月</td>
                </tr>
                <tr>
                  <td colSpan={2}>Eコマースシステム(別途決済手数料)</td>
                </tr>
                <tr>
                  <td>+8000円/月</td>
                  <td>+160000円</td>
                </tr>
                <tr>
                  <td colSpan={2}>スマート予約システム</td>
                </tr>
                <tr>
                  <td>+5000円/月</td>
                  <td>+100000円</td>
                </tr>
                <tr>
                  <td colSpan={2}>多言語化対応(1言語ごと)</td>
                </tr>
                <tr>
                  <td>+5000円/月</td>
                  <td>+100000円</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingPlan;
