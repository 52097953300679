import React, { useEffect, useState } from 'react';
import * as styles from './Accordion.module.css';

type ColorType = 'primary' | 'secondary';

type AccordionItem = {
  question: string;
  answer: string;
};

type AccordionProps = {
  title: string;
  subTitle: string;
  colorType: ColorType;
  items: AccordionItem[];
};

const Accordion: React.FC<AccordionProps> = ({
  items,
  title,
  subTitle,
  colorType,
}) => {
  let accordionSectionClass = styles.accordionSectionPrimary; // デフォルトのスタイル
  if (colorType === 'primary') {
    accordionSectionClass = styles.accordionSectionPrimary;
  } else if (colorType === 'secondary') {
    accordionSectionClass = styles.accordionSectionSecondary;
  }

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  useEffect(() => {
    setOpenIndex(null);
  }, []);

  return (
    <section className={accordionSectionClass} id="accordion">
      <div className={styles.accordionSectionInner}>
        <div className={styles.title}>
          <h1>
            <span className={styles.titleEn}>Q&A </span>
            <span className={styles.titleJp}>よくある質問</span>
          </h1>
        </div>
        <ul className={styles.accordionArea}>
          {items.map((item, index) => (
            <li key={index}>
              <section>
                <h3
                  className={
                    openIndex === index
                      ? `${styles.title} ${styles.close}`
                      : styles.title
                  }
                  onClick={() =>
                    setOpenIndex(index === openIndex ? null : index)
                  }
                >
                  {item.question}
                </h3>
                {openIndex === index && (
                  <div className={openIndex === index ? styles.open : ''}>
                    {item.answer}
                  </div>
                )}
              </section>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Accordion;
